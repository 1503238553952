// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// // NEW SIDEBAR
// /*
// * becareful this code is begun to be optimized but isn't finished
// * the older code was in style.css
// */

// #sidebar {
//   position: relative;
//   min-width: 250px;
//   max-width: 250px;
//   background: #222224;
//   color: #fff;
//   transition: all 0.3s;
//   position: relative; 

//   @include media-breakpoint-down(lg){
//     margin-left: -250px; 
//   }

//   .h6{
//     color: #fff;
//   }
//   &.active{
//     margin-left: -250px;

//     @include media-breakpoint-down(lg){
//       margin-left: 0; 
//     }
//     @include media-breakpoint-down(sm){
//       width: 100vw;
//       height: 100vh;
//       max-width: initial;
//       margin: 0;
//       padding: 80px 20px 20px;

//       .custom-menu{
//         margin: 0 !important;
//         right: 10px;
//       }
//     }

//     .custom-menu {
//       margin-right: -50px; 
//     }
//   }
//   .custom-menu {
//     display: inline-block;
//     position: absolute;
//     top: 20px;
//     right: 0;
//     margin-right: -20px;
//     transition: 0.3s; 

//     .btn {
//       width: 40px;
//       height: 40px;
//       border-radius: 50%; 
      
//       &.btn-primary {
//         background: #232329;
//         border-color: #0a0a0a; 
        
//         &:hover, #sidebar .custom-menu .btn.btn-primary:focus {
//           background: #17171c !important;
//           border-color: #000000 !important; 
//         }
//       }
//     }
//     @media (prefers-reduced-motion: reduce) {
//       transition: none; 
//     }

//     @include media-breakpoint-down(lg){
//       margin-right: -50px;
//       top: 10px !important; 
//     }
//   }
  
//   h1{
//     margin-bottom: 20px;
//     font-weight: 700;

//     .logo {
//       color: #fff; 
//     }
//   }
//   ul{
//     &.components {
//       padding: 0; 
//     }
//     li {
//       font-size: 16px; 

//       & > ul {
//         margin-left: 10px; 

//         li {
//           font-size: 14px; 
//         }
//       }

//       a {
//         padding: 10px 0;
//         display: block;
//         color: rgba(255, 255, 255, 0.8);

//         &:hover {
//           color: #fff; 
//         }
//       }
//       &.active > a {
//         background: transparent;
//         color: #fff; 
//       }
//     }
//   }

//   @include media-breakpoint-down(lg){   
//     #sidebarCollapse span {
//       display: none; 
//     } 
//   }
// }


/* VARIABLES */
$sidebar-desktop-width: 250px;

aside#sidebar{
  position: relative;
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $dark;
  overflow: hidden;
  z-index: 11;
  transition: all .2s;

  &.active{
    @include media-breakpoint-up(md){
      width: $sidebar-desktop-width;
      overflow: unset;
    }
  }
  &.mobile-active{
    @include media-breakpoint-down(sm){
      overflow: initial;
      width: 100%;
    }
  }

  nav{
    padding-top: 4rem;
    @include media-breakpoint-up(md){
    padding-top: 2rem;
      img{
        max-width: calc(#{$sidebar-desktop-width}-48px);
        padding-top: 2rem;
      }
    }
    
    li{
      &:not(:last-child){
        margin-bottom: 20px;
      }
      &.active{
        a{
          color: $white;
          &:hover{
            color: $white;
          }
        }
      }
      a{
        color: $medium-light;
        transition: all .2s;

        &:hover{
          color: $white;
          text-decoration: none;
        }
      }
    }
  }
}

/* FOOTER */
.footer{
  color: rgba($color: $white, $alpha: 0.5);
  font-size: 0.875rem;
}

/* BUTTONS and MARGIN-LEFT*/
.btn-aside{
  position: absolute;
  top: 10px;
  border-radius: 100px;
  z-index: 10;
}
aside#sidebar{
  .btn-aside{
    right: 10px;
    padding: 6px 13px;

    @include media-breakpoint-up(md){
      right: -22px;
    }
  }
}
main{
  transition: all .2s;
  .btn-aside{
    left: 10px;
    padding: 6px 12px;
  }
  &.custom-container-fluid{
    @include media-breakpoint-up(md){
      margin-left: $sidebar-desktop-width;
    }
  }
}
